<template>
  <div
    ref="container"
    class="card card-custom gutter-b bg-transparent"
    style="box-shadow: none !important"
  >
    <!--begin::Activity Preview Cards-->
    <div class="card-body p-0">
      <div
        v-if="applicableActivities.length > 0"
        class="row d-flex justify-content-start"
      >
        <div
          v-for="(item, i) in applicableActivities"
          v-bind:key="i"
          class="col-xl-4 col-md-4 col-sm-4 col-xs-4 col-lg-4"
        >
          <a :href="activityLink(item)">
            <ActivityPreviewCards
              :activityDetails="item"
            ></ActivityPreviewCards>
          </a>
        </div>
      </div>
      <div v-else>
        <b-alert show variant="transparent">
          <div class="text-center">
            No Activities Found
          </div>
        </b-alert>
      </div>
    </div>
    <!--end::Activity Preview Cards-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import RoomPreviewCards from "@/view/pages/activity/RoomPreviewCards.vue";
import ActivityPreviewCards from "@/view/pages/activity/ActivityPreviewCards.vue";

export default {
  name: "view_activities",
  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 2
      },
      error: {
        active: false,
        message: ""
      }
    };
  },
  components: {
    // RoomPreviewCards,
    ActivityPreviewCards
  },
  methods: {
    activityLink(activity) {
      return "/#/activity/" + activity.id;
    }
  },
  computed: {
    ...mapGetters(["activityList", "projectRoomSetupList", "currentUser"]),

    applicableActivities: function() {
      let activities = this.activityList.filter(function(activity) {
        return activity.system_activity_type_id != 9;
      });

      if (this.projectRoomSetupList.length > 0) {
        if (this.projectRoomSetupList[0].rooms_exclusive) {
          activities = activities.filter(function(activity) {
            return activity.system_project_room_id == null;
          });
        }
      }

      if (this.currentUser.relation_to_project == "participant") {
        activities = activities.filter(function(activity) {
          return activity.system_activity_stage_id > 2;
        });
      }

      return activities;
    }
  }
};
</script>
