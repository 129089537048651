<template>
  <div>
    <b-card
      no-body
      class="text-center card-custom gutter-b activity-border"
      v-bind:class="{ 'folded-corner': isSequencedActivity }"
      :id="isSequencedActivity ? 'sequence-label-' + activityDetails.id : ''"
    >

      <img class="h-200px" :style="selectedThumbnail" style="background-position: center; background-size: cover">

      <!--begin::Type Icon-->
      <span class="custom-type-icon">
        <i
          :class="activityIcon(activityDetails)"
          v-b-tooltip.hover.right
          :title="$t(iconLabel(activityDetails))"
        >
        </i
      ></span>
      <!--end::Type Icon-->

      <b-card-body>
        <b-card-title style="max-height: 15px">{{
          activityDetails.title
        }}</b-card-title>
        <b-card-text>
          <!--begin::Desc-->
          <p
            class="text-dark-50 font-weight-normal font-size-sm mb-7 text-center desc-ellipsis"
          >
            {{ activityDetails.summary }}
          </p>
          <!--end::Desc-->
          <!--begin::Info-->
          <div
            class="d-flex align-items-between justify-content-between pr-2 footer-status"
          >
            <div class="d-flex justify-content-start font-size-xs">
              <!--start::Relative Status-->
              <span
                class="mr-5 font-weight-bold"
                :class="relativeStatus.class"
                v-b-tooltip.hover.bottom
                :title="relativeStatus.tooltip"
              >
                {{ relativeStatus.text }}
              </span>
              <!--end::Relative Status-->
            </div>
            <div class="d-flex justify-content-start font-size-xs">
              <span class="mr-2 text-muted">
                {{ relativeDate.prefix }}
              </span>
            </div>
          </div>
          <!--end::Info-->
        </b-card-text>
      </b-card-body>
    </b-card>

    <b-tooltip
      placement="right"
      :target="'sequence-label-' + activityDetails.id"
      triggers="hover"
    >
      {{ sequenceLabel }}
    </b-tooltip>
  </div>
</template>

<script>
import SystemIconHelper from "@/modules/together-helpers/helpers/SystemIconHelper";
import SystemLabelHelper from "@/modules/together-helpers/helpers/SystemLabelHelper";

export default {
  name: "activity-preview-cards",
  props: {
    activityDetails: Object
  },
  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1
      },
      error: {
        active: false,
        message: ""
      }
    };
  },

  components: {
    // GenericDatetimeDisplay
  },

  mounted() {
    this.pageLoader.componentsCompleted++;
  },

  methods: {
    activityIcon(activity) {
      return (
        "font-size-h4 text-primary text-hover-dark " +
        SystemIconHelper.activityType(
          activity.activity_type,
          activity.recording_override
        )
      );
    },
    iconLabel(activity) {
      return SystemLabelHelper.activityType(
        activity.activity_type,
        activity.recording_override
      );
    }
  },

  computed: {
    isSequencedActivity: function() {
      let isChild = this.activityDetails.sequence_position !== null;
      let inSequence = this.activityDetails.total_sequence_length !== null;
      return isChild && inSequence;
    },
    sequenceLabel: function() {
      let position = this.activityDetails.sequence_position;
      let total = this.activityDetails.total_sequence_length;
      return this.isSequencedActivity
        ? "Part: " + position + " of " + total
        : null;
    },
    relativeStatus: function() {
      let status = {};

      switch (this.activityDetails.completion_state) {
        case "eligible":
          status.text = `${this.$t("ACTIVITY.COMPLETE_STATUS_SHORT.NEW")}`;
          status.class = "text-primary";
          status.tooltip = `${this.$t("ACTIVITY.COMPLETE_STATUS_TOOLTIP.NEW")}`;
          break;
        case "no_participation":
          status.text = `${this.$t("ACTIVITY.COMPLETE_STATUS_SHORT.CLOSED")}`;
          status.class = "text-danger";
          status.tooltip = `${this.$t("ACTIVITY.COMPLETE_STATUS_TOOLTIP.CLOSED")}`;
          break;
        case "completed":
          status.text = `${this.$t("ACTIVITY.COMPLETE_STATUS_SHORT.COMPLETE")}`;
          status.class = "text-success";
          status.tooltip = `${this.$t("ACTIVITY.COMPLETE_STATUS_TOOLTIP.COMPLETE")}`;
          break;
        case "posted":
          status.text = `${this.$t("ACTIVITY.COMPLETE_STATUS_SHORT.ANSWERED")}`;
          status.class = "text-warning";
          status.tooltip = `${this.$t("ACTIVITY.COMPLETE_STATUS_TOOLTIP.ANSWERED")}`;
          break;
        case "not_eligible":
          status.text = `${this.$t("ACTIVITY.COMPLETE_STATUS_SHORT.NOT_ELIGIBLE")}`;
          status.class = "text-danger";
          status.tooltip = `${this.$t("ACTIVITY.COMPLETE_STATUS_TOOLTIP.NOT_ELIGIBLE")}`;
          break;
      }

      return status;
    },
    relativeSocialSetting: function() {
      let setting = {};

      switch (this.activityDetails.system_social_setting_id) {
        case 1:
          setting.class = "fas fa-lock-open";
          setting.text = `${this.$t("ACTIVITY.SCOPE.PUBLIC")}`;
          setting.tooltip = `${this.$t("ACTIVITY.SCOPE_TOOLTIP.PUBLIC")}`;
          break;
        case 2:
          setting.class = "fas fa-lock-open";
          setting.text = `${this.$t("ACTIVITY.SCOPE.SEGMENT")}`;
          setting.tooltip = `${this.$t("ACTIVITY.SCOPE_TOOLTIP.SEGMENT")}`;
          break;
        case 3:
          setting.class = "fas fa-lock";
          setting.text = `${this.$t("ACTIVITY.SCOPE.PRIVATE")}`;
          setting.tooltip = `${this.$t("ACTIVITY.SCOPE_TOOLTIP.PRIVATE")}`;
          break;
      }

      return setting;
    },
    relativeDate: function() {
      let date = {};

      switch (this.activityDetails.system_activity_stage_id) {
        case 1:
          date.prefix = `${this.$t("ACTIVITY.STATUS.DRAFT")}`;
          date.date = null;
          break;
        case 2:
          date.prefix = `${this.$t("ACTIVITY.STATUS.OPENS")}`;
          date.date = this.activityDetails.starts_on;
          break;
        case 3:
          date.prefix = `${this.$t("GENERAL.STATUS.ACTIVE")}`;
          date.date = this.activityDetails.ends_on;
          break;
        case 4:
          date.prefix = `${this.$t("ACTIVITY.STATUS.CLOSED")}`;
          date.date = this.activityDetails.ends_on;
          break;
      }

      return date;
    },
    firstActivityStimuli: function () {
      let imageBasedStimuli = [];
      let imageForeignKey = null;

      switch (this.activityDetails.system_activity_type_id) {
        case 10:
          imageForeignKey = 'engagement_stimuli_assets';
          break;
        default:
          imageForeignKey = 'stimulus_assets';
          break;
      }
      
      imageBasedStimuli = this.activityDetails[imageForeignKey].filter(function(stimuli) {
        return stimuli.type == 2
      });

      if (imageBasedStimuli.length > 0) {
        let url = null;
        let file = imageBasedStimuli[0];
        if (file.signedThumbUrl !== null) {
          url = file.signedThumbUrl;
        } else {
          if (file.signedUrl !== null) {
            url = file.signedUrl;
          } else {
            url = "null";
          }
        }
        return url == "null" ? "background-image: url(/media/misc/download-asset.png)" : `background-image: url(${url})`;
      } else {
        return null;
      }
    },
    fallbackActivityThumbnail: function() {
      let systemActivityTypeId = this.activityDetails.activity_type.id;
      systemActivityTypeId = (systemActivityTypeId <= 9 ? "0" + systemActivityTypeId : systemActivityTypeId);
      return `background-image: url(/img/fallbacks/activity_types/_${systemActivityTypeId}.jpg)`;
    },
    selectedThumbnail: function() {
      if (
        process.env.VUE_APP_API_ROOT === 'tolunastart-qual.com' &&
        this.activityDetails.project_id === 496
      ) {
        switch (this.activityDetails.id) {
          case 5260:
            return `background-image: url(/img/fallbacks/activity_types/_01.jpg)`;
          case 5261:
            return `background-image: url(/img/fallbacks/activity_types/_02.jpg)`;
          case 5338:
            return `background-image: url(/img/fallbacks/activity_types/_08.jpg)`;
          case 5263:
            return `background-image: url(/img/fallbacks/activity_types/_07.jpg)`;
          case 5264:
            return `background-image: url(/img/fallbacks/activity_types/_09.jpg)`;
          case 5265:
            return `background-image: url(/img/fallbacks/activity_types/_06.jpg)`;
          default:
            return (this.firstActivityStimuli == null) ? this.fallbackActivityThumbnail : this.firstActivityStimuli;
        }
      } else {
        return (this.firstActivityStimuli == null) ? this.fallbackActivityThumbnail : this.firstActivityStimuli;
      }
    }
  }
};
</script>
<style scoped>
.img-thumbnail {
  width: 100%;
  height: 200px;
  object-fit: cover;

  padding: unset;
  background-color: unset;
  border: unset;
  border-top-left-radius: calc(0.42rem - 1px);
  border-top-right-radius: calc(0.42rem - 1px);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-box-shadow: unset;
  box-shadow: unset;
}
.activity-border {
  border: 2px solid white;
}
h4.card-title {
  font-size: 1.15rem;
}
.custom-type-icon {
  position: absolute;
  left: 50%;
  top: 200px;

  margin-top: -25px;
  margin-left: -25px;
  display: flex;
  justify-content: center;
  justify-items: center;

  height: 50px;
  width: 50px;
  padding: 0.8rem;
  border-radius: 50%;
  background-color: #fff;
}

@media screen and (max-width: 600px) {
  .custom-type-icon {
    visibility: hidden;
    display: none;
  }
}
.footer-status {
  position: absolute;
  width: 95%;
  left: 15px;
}
.desc-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 50px;
}
.folded-corner:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 30px 30px 0;
  border-style: solid;
  border-color: #fff #e4e7ed;
  -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 30%), -1px 1px 1px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3), -1px 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px rgb(0 0 0 / 30%), -1px 1px 1px rgb(0 0 0 / 20%);
}
</style>
